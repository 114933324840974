.body {
    background-color: #aacfcf;
}

.navbar {
    background-color: #F8F9FA !important;
    z-index: 99;
}

.brand {
    color: #363636 !important;
    font-size: 32px;
    font-weight: bold;
}

.title_landing {
    color: #FA7070;
    font-size: 64px;
    font-weight: bold;
}

.deskripsi_landing {
    color: #616161;
    font-size: 24px;
    font-weight: bold;
}

.wrapper_landing {
    position: relative;
    width: fit-content;
    height: 50vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    z-index: 99;
}

.phone {
    position: absolute;
    right: 200px;
    bottom: 180px;
    width: 250px;
    z-index: 98;
}

.circle1 {
    position: fixed;
    bottom: -60px;
    right: -60px;
    width: 350px;
    height: 350px;
    border-radius: 50%;
    background-color: #FFB6B6;
    z-index: 1;
    /* Add any other styles you want */
}

.circle2 {
    position: fixed;
    bottom: -60px;
    right: 350px;
    width: 250px;
    height: 250px;
    border-radius: 50%;
    background-color: #FDE2E2;
    z-index: 1;
    /* Add any other styles you want */
}

.circle3 {
    position: fixed;
    top: 0;
    right: -50px;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    background-color: #A1C298;
    z-index: 1;
    /* Add any other styles you want */
}

.bar1 {
    position: fixed;
    right: 10px;
    bottom: 390px;
    width: 700px;
    height: 20px;
    background-color: #E9CBCB;
    z-index: 2;
    rotate: 70deg;
}

.bar2 {
    position: fixed;
    right: -50px;
    bottom: 400px;
    width: 700px;
    height: 20px;
    background-color: #E99D9D;
    z-index: 2;
    rotate: 70deg;
}

@media (max-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */
    .bar1 {
        display: none;
    }
    .bar2 {
        display: none;
    }
    .phone {
        display: none;
    }
    
    .wrapper_landing {
        width: 100%;
        height: 80vh;
        justify-content: center;
        align-items: center;
    }
}